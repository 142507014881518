@import '../../style/_variables.scss';
@import '../../style/_mixins.scss';

section.mission-pillar-section {
  &.get-involved-section {
    .pillars-card {
      display: flex;
      gap: 55px;
      padding-top: 0;
      justify-content: center;
      flex-wrap: wrap;
    }

    p {
      font-size: 18px;
    }
  }
}

.donation-info-section {
  .form {
    max-width: 1080px;
    margin: 0 auto;
    padding: 40px 0 0;

    .amount-section {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      max-width: 606px;

      .amount-btn {
        display: flex;
        height: 41px;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        &.btn-orange {
          color: $text-color;

          &.enter-amount {
            grid-column: 1/-4;
          }

          &.input-box {
            background: $color-white;
            grid-column: 3/-1;
            justify-content: flex-start;

            input {
              width: 100%;
              height: 41px;
              border: none;
            }

            &:hover {
              background: $color-white !important;
            }
          }
        }
      }
    }

    .checkbox {
      display: grid;
      gap: 15px;
      margin-top: 20px;

      input {
        width: 20px;
        height: 20px;
        position: relative;
        bottom: 2px;
        margin-right: 10px;
      }
    }

    .information-form-secton {
      // margin-top: 56px;
      padding: 30px 0;

      .form-group {
        margin-bottom: 15px;

        label {
          background: none;
          border: none;
          padding-left: 0;
          font-family: Brandon Grotesque bold !important;
        }
      }

      .form-check {
        margin-bottom: 20px;
      }

      .note {
        margin-top: 20px;

        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .btn {
      width: 156px;
      white-space: nowrap;
      display: flex !important;
      align-items: center;
    }
  }
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;

  .btn {
    color: $text-color;
    margin: 0px;
  }
}

.marathon-images {
  max-width: 1080px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .donation-info-section .form {
    padding: 40px 20px;
  }

  .marathon-images {
    padding: 20px;
  }
  .generic-text.top-80 {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 991px) {
  section.mission-pillar-section.get-involved-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .donation-info-section .form {
    padding: 40px 30px;
  }
}

@media screen and (max-width: 767px) {
  .donation-info-section {
    .form {
      .amount-section {
        grid-template-columns: 1fr 1fr;

        .amount-btn {
          &.btn-orange {
            &.input-box {
              grid-column: 4/-3;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  h6.pb-4 {
    padding-top: 40px !important;
  }
}

@media screen and (max-width: 580px) {
  .donation-info-section {
    .form {
      padding: 0px 30px;

      .amount-section {
        gap: 10px;

        .amount-btn {
          &.btn-orange {
            &.enter-amount {
              grid-column: 1/-1;
            }

            &.input-box {
              grid-column: 1/-1;
            }
          }
        }
      }

      .checkbox {
        .form-check {
          display: flex;
        }
      }
    }
  }

  .marathon-images {
    grid-template-columns: 1fr 1fr;
    padding: 12px;
    gap: 10px;
    padding-bottom: 0 !important;
  }
}
